import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./workflowpredicto.module.scss"
import predicto from "../../images/ai-hologram/predicto.svg"
function Workflowpredicto({ strapiData }) {
  return (
    <div className={styles.predictoContainer}>
      <Container>
        <h2
          className={styles.workflowPredictoHeading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />

        {/* Predicto banner - visible only on desktop */}
        <div className={styles.desktopOnly}>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_hologram_de31b81043.webp"
            }
            alt="predicto"
          />
        </div>

        {/* Workflow grid - visible on mobile and tablet */}
        <div className={styles.mobileTabletOnly}>
          <div className={styles.workflowGridContainerMobile}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/aihiologram_desktop_7a047443fe.webp"
              }
              alt="predicto"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Workflowpredicto
