// extracted by mini-css-extract-plugin
export var arrowRight = "workflowpredicto-module--arrowRight--d0951";
export var blue = "workflowpredicto-module--blue--7f178";
export var bottomItems = "workflowpredicto-module--bottomItems--7befd";
export var desktopOnly = "workflowpredicto-module--desktopOnly--14146";
export var downArrow = "workflowpredicto-module--downArrow--fab7e";
export var mobileTabletOnly = "workflowpredicto-module--mobileTabletOnly--b448d";
export var predictoContainer = "workflowpredicto-module--predictoContainer--efdfd";
export var predictoInner = "workflowpredicto-module--predictoInner--8659d";
export var rightArrow = "workflowpredicto-module--rightArrow--e01fe";
export var stepNumber = "workflowpredicto-module--stepNumber--f8905";
export var stepText = "workflowpredicto-module--stepText--2a856";
export var workflowGrid = "workflowpredicto-module--workflowGrid--d82ec";
export var workflowGridContainerMobile = "workflowpredicto-module--workflowGridContainerMobile--4bc2d";
export var workflowGridOuter = "workflowpredicto-module--workflowGridOuter--a4bd4";
export var workflowGridmain = "workflowpredicto-module--workflowGridmain--d3a72";
export var workflowItem = "workflowpredicto-module--workflowItem--0ec4a";
export var workflowPredictoHeading = "workflowpredicto-module--workflowPredictoHeading--dfccf";