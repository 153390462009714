import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/ai-hologram/Banner"
import Technologiesweuse from "../../components/churn/Technologiesweuse"
import AIConsultingService from "../../components/ai-hologram/Introsection"
import KeyFeatures from "../../components/ai-hologram/KeyFeatures"
import SEORevamp from "../../components/common/SEO_Revamp"
import CenterImage from "../../components/ai-hologram/CenterImage"
import Conclusion from "../../components/ai-hologram/Conclusion"
import Contributions from "../../components/ai-hologram/Contributions"
import Workflowpredicto from "../../components/ai-hologram/WorkflowPredicto"
import KeyChallengesSolution from "../../components/ai-hologram/KeyChalangesSolution"
import WhyInvozone from "../../components/ai-hologram/WhyInvo"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  console.log("All GraphQL Data:", data)

  // Log specific sections
  console.log("Workflow Data:", data?.allStrapiAiHologram?.nodes[0]?.workflow)
  console.log(
    "Workflow Steps:",
    data?.allStrapiAiHologram?.nodes[0]?.workflow?.workflow_steps?.data
  )
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const AIDevelopment = ({ data }) => {
  console.log(data)

  const heroSection = data?.strapiPage?.sections[0]
  const buttonsSection = data?.strapiPage?.sections[0]
  const consultingService = data?.strapiPage?.sections[1]
  const contributions = data?.strapiPage?.sections[4]
  const key = data?.strapiPage?.sections[5]
  const engagementModel = data?.strapiPage?.sections[2]
  const conclusion = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[9]
  const workflowpredicto = data?.strapiPage?.sections[3]
  const keyChallengesSolution = data?.strapiPage?.sections[5]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Technologiesweuse hideCurve={true} strapiData={buttonsSection} />
      <AIConsultingService strapiData={consultingService} />
      <KeyFeatures strapiData={engagementModel} />
      <Workflowpredicto strapiData={workflowpredicto} />
      <Contributions strapiData={contributions} />
      <KeyChallengesSolution strapiData={keyChallengesSolution} />
      <CenterImage />
      <Conclusion strapiData={conclusion} />
      <WhyInvozone />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "ai-hollogram" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            caseServices {
              invocom_caseStudy {
                skills
              }
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default AIDevelopment
