// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner1-module--arrowAnimation--9ccb0";
export var backgroundImg = "Banner1-module--backgroundImg--90cf0";
export var bannerCurve = "Banner1-module--bannerCurve--7bad2";
export var bannerHeading = "Banner1-module--bannerHeading--b34b7";
export var bannerText = "Banner1-module--bannerText--843dd";
export var btn = "Banner1-module--btn--8e11c";
export var btne = "Banner1-module--btne--92f8d";
export var curve = "Banner1-module--curve--f1f38";
export var dedicated = "Banner1-module--dedicated--45a75";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--ff57f";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--83190";
export var description = "Banner1-module--description--a8c33";
export var descriptionText = "Banner1-module--descriptionText--7b5c2";
export var headingIntro = "Banner1-module--headingIntro--ed5e9";
export var innerDedicated = "Banner1-module--innerDedicated--74837";
export var mainTitle = "Banner1-module--mainTitle--044e0";
export var newBtn = "Banner1-module--newBtn--1ce6f";
export var paragraphAi = "Banner1-module--paragraphAi--1b582";
export var sliderButtons = "Banner1-module--sliderButtons--f43e8";
export var subTitle = "Banner1-module--subTitle--f8b6d";