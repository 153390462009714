import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from './WhyInvozone.module.scss';

const WhyInvozone = () => {
  const reasons = [
    {
      number: "01",
      title: "Expertise in AI, 3D Rendering, and WebGL."
    },
    {
      number: "02",
      title: "Proven Ability To Overcome Complex Challenges."
    },
    {
      number: "03",
      title: "Scalable Solutions Tailored For Diverse Industries."
    },
    {
      number: "04",
      title: "Backend System, Avatar End User Communication"
    }
  ];

  return (
    <div className={styles.whySection}>
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className={styles.mainHeading}>Why Invozone?</h2>
          </Col>
          <Col lg={8}>
            <Row>
              {reasons.map((reason, index) => (
                <Col lg={6} key={index}>
                  <div className={styles.reasonCard}>
                    <span className={styles.number}>{reason.number}</span>
                    <p className={styles.title}>{reason.title}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyInvozone;